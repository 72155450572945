import { getUrlParam, normalizeFilterParam } from "helpers/url-params";

export const sortProducts = (products, sort) => {
	const productsCopy = [...products];
	switch (sort) {
		case "manual":
			return products;
		case "best-selling":
			return productsCopy.sort((a, b) => b.orderedQuantity - a.orderedQuantity);
		case "title-ascending":
			return productsCopy.sort((a, b) => a.title.localeCompare(b.title));
		case "title-descending":
			return productsCopy.sort((a, b) => b.title.localeCompare(a.title));
		case "price-ascending":
			return productsCopy.sort((a, b) => a.minVariantPrice - b.minVariantPrice);
		case "price-descending":
			return productsCopy.sort((a, b) => b.minVariantPrice - a.minVariantPrice);
		case "created-ascending":
			return productsCopy.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
		case "created-descending":
			return productsCopy.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
		default:
			return productsCopy.sort((a, b) => b.orderedQuantity - a.orderedQuantity);
	}
};

export const mapActiveFilters = (tags) =>
	Object.entries(tags || {}).reduce((acc, [key, value]) => {
		acc[key] = Object.keys(value).filter(tag => value[tag]);
		return acc;
	}, {});


const matchesFilters = (tags, filters, matchAllFilters) => {
	const filterCheck = ([, selectedTags]) =>
		selectedTags.length === 0 || selectedTags.some(tag => tags?.includes(tag));

	return matchAllFilters
		? Object.entries(filters).every(filterCheck)
		: Object.entries(filters).some(filterCheck);
};

const findMatchingVariant = (variants, activeFilters, matchAllFilters) => {
	if (!variants) return null;

	const matchesFilters = (variant) => {
		return Object.entries(activeFilters).every(([, selectedTags]) =>
			selectedTags.length === 0 || selectedTags.some(tag => variant.tags?.includes(tag))
		);
	};

	const matchesAnyFilter = (variant) => {
		return Object.entries(activeFilters).some(([, selectedTags]) =>
			selectedTags.length !== 0 && selectedTags.some(tag => variant.tags?.includes(tag))
		);
	};

	return variants.find(variant => matchAllFilters ? matchesFilters(variant) : matchesAnyFilter(variant));
};

export const filterProducts = ({ products, collectionFilters, filters, matchAllFilters = false }) => {
	// matchAllFilters = true means the product must match all filters
	const { tags, priceRange } = collectionFilters;
	const activeFilters = filters || mapActiveFilters(tags);
	const minPrice = priceRange?.min || 0;
	const maxPrice = priceRange?.max || Infinity;

	const filteredProducts = products
		.map(product => {
			const { minVariantPrice, tags: productTags = [], variants } = product;
			const priceMatch = minVariantPrice >= minPrice && minVariantPrice <= maxPrice;
			const tagsMatch = matchesFilters(productTags, activeFilters, matchAllFilters);
			const matchingVariant = findMatchingVariant(variants, activeFilters, matchAllFilters);

			if ((tagsMatch || matchingVariant) && priceMatch) {
				return matchingVariant ? { ...product, variant: matchingVariant } : product;
			}

			return null;
		})
		.filter(Boolean);

	return filteredProducts;
};

export const mapFilterTags = (filters) => {
	if (!filters) return null;

	const mappedFilters = filters.reduce((acc, item) => {
		acc[item.name] = item.data.reduce((tags, tagItem) => {
			tags[tagItem.tag] = false;
			return tags;
		}, {});
		return acc;
	}, {});

	Object.keys(mappedFilters).forEach(category => {
		const urlTags = getUrlParam(normalizeFilterParam(category));
		if (urlTags) urlTags.split(",").forEach(t => {
			if (Object.hasOwn(mappedFilters[category], t))
				mappedFilters[category][t] = true;
		});
	});

	return mappedFilters;
};
