import { addCartItem, removeCartItem, updateCartItem, fetchCart } from "actions/cart";

const initialState = {
	items: [],
	createdAt: null,
	webUrl: "",
	totalPrice: 0,
	totalCompareAtPrice: 0,
	totalQuantity: 0,
	discountCode: "",
	isLoading: true,
	isUpdating: false,
	error: false,
	checkoutLoading: false,
	recommendations: null,
};


const cartSlice = (set, get) => ({
	// Properties
	...initialState,

	// actions

	fetchCart: () => fetchCart(set, get),

	addCartItem: (variantId, quantity) => addCartItem(set, get, variantId, quantity),

	updateCartItem: (id, quantity) => updateCartItem(set, get, id, quantity),

	removeCartItem: (id) => removeCartItem(set, get, id),

	updateBuyerIdentity: (id) => {
		const cartId = id || get().cart.id;
		return get().shopifyClient.cart.buyerIdentityUpdate(cartId);
	}

});

export default cartSlice;